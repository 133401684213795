<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Rujukan</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-8" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpRujukan" class="form-control" 
                            placeholder="Ketik No Rujukan" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group mb-0">
                                <v-select placeholder="Pilih Type" v-model="jnsRujukan" :options="Config.mr.typeRujukanRS" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchRujukan" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetRujukan">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwRujukan">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>diagnosa</h4>
                                                <p>kode : {{dataRujukan.rujukan.diagnosa.kode||"-"}}</p>
                                                <p>nama : {{dataRujukan.rujukan.diagnosa.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>keluhan</h4>
                                            <p>{{dataRujukan.rujukan.keluhan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noKunjungan</h4>
                                            <p>{{dataRujukan.rujukan.noKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pelayanan</h4>
                                                <p>kode : {{dataRujukan.rujukan.pelayanan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukan.rujukan.pelayanan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukan.rujukan.poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukan.rujukan.poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provPerujuk</h4>
                                                <p>kode : {{dataRujukan.rujukan.provPerujuk.kode||"-"}}</p>
                                                <p>nama : {{dataRujukan.rujukan.provPerujuk.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglKunjungan</h4>
                                                <p>{{dataRujukan.rujukan.tglKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukan.rujukan.poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukan.rujukan.poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    
                                    <tr>
                                        <td colspan="3">Data Pasien</td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Kartu</h4>
                                            <p>{{dataRujukan.rujukan.peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{dataRujukan.rujukan.peserta.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{dataRujukan.rujukan.peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pisa</h4>
                                            <p>{{dataRujukan.rujukan.peserta.pisa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>sex</h4>
                                            <p>{{dataRujukan.rujukan.peserta.sex||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                            <p>{{dataRujukan.rujukan.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglCetakKartu</h4>
                                            <p>{{dataRujukan.rujukan.peserta.tglCetakKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTAT</h4>
                                            <p>{{dataRujukan.rujukan.peserta.tglTAT||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTMT</h4>
                                            <p>{{dataRujukan.rujukan.peserta.tglTMT||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>statusPeserta</h4>
                                            <p>Kode : {{dataRujukan.rujukan.peserta.statusPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukan.rujukan.peserta.statusPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>mr</h4>
                                            <p>noMR : {{dataRujukan.rujukan.peserta.mr.noMR||"-"}}</p>
                                            <p>noTelepon : {{dataRujukan.rujukan.peserta.mr.noTelepon||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provUmum</h4>
                                            <p>kdProvider : {{dataRujukan.rujukan.peserta.provUmum.kdProvider||"-"}}</p>
                                            <p>nmProvider : {{dataRujukan.rujukan.peserta.provUmum.nmProvider||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr
                                    >  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jenisPeserta</h4>
                                            <p>Kode : {{dataRujukan.rujukan.peserta.jenisPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukan.rujukan.peserta.jenisPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>hakKelas</h4>
                                            <p>Kode : {{dataRujukan.rujukan.peserta.hakKelas.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukan.rujukan.peserta.hakKelas.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 

                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>umur</h4>
                                            <p>umurSekarang : {{dataRujukan.rujukan.peserta.umur.umurSekarang||"-"}}</p>
                                            <p>umurSaatPelayanan : {{dataRujukan.rujukan.peserta.umur.umurSaatPelayanan||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>informasi</h4>
                                            <p>dinsos : {{dataRujukan.rujukan.peserta.informasi.dinsos||"-"}}</p>
                                            <p>prolanisPRB : {{dataRujukan.rujukan.peserta.informasi.prolanisPRB||"-"}}</p> 
                                            <p>noSKTM : {{dataRujukan.rujukan.peserta.informasi.noSKTM||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>noAsuransi : {{dataRujukan.rujukan.peserta.cob.noAsuransi||"-"}}</p>
                                            <p>nmAsuransi : {{dataRujukan.rujukan.peserta.cob.nmAsuransi||"-"}}</p> 
                                            <p>tglTMT : {{dataRujukan.rujukan.peserta.cob.tglTMT||"-"}}</p> 
                                            <p>tglTAT : {{dataRujukan.rujukan.peserta.cob.tglTAT||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Rujukan Berdasarkan No Kartu</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-8" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpRujukanNoKa" class="form-control" 
                            placeholder="Ketik No Kartu" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group mb-0">
                                <v-select placeholder="Pilih Type" v-model="jnsRujukanNoKa" :options="Config.mr.typeRujukanRS" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchRujukanNoKa" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetRujukanNoKa">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwRujukanNoKa">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>diagnosa</h4>
                                                <p>kode : {{dataRujukanNoKa.rujukan.diagnosa.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKa.rujukan.diagnosa.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>keluhan</h4>
                                            <p>{{dataRujukanNoKa.rujukan.keluhan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noKunjungan</h4>
                                            <p>{{dataRujukanNoKa.rujukan.noKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pelayanan</h4>
                                                <p>kode : {{dataRujukanNoKa.rujukan.pelayanan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKa.rujukan.pelayanan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukanNoKa.rujukan.poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKa.rujukan.poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provPerujuk</h4>
                                                <p>kode : {{dataRujukanNoKa.rujukan.provPerujuk.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKa.rujukan.provPerujuk.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglKunjungan</h4>
                                                <p>{{dataRujukanNoKa.rujukan.tglKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukanNoKa.rujukan.poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKa.rujukan.poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    
                                    <tr>
                                        <td colspan="3">Data Pasien</td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Kartu</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pisa</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.pisa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>sex</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.sex||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglCetakKartu</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.tglCetakKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTAT</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.tglTAT||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTMT</h4>
                                            <p>{{dataRujukanNoKa.rujukan.peserta.tglTMT||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>statusPeserta</h4>
                                            <p>Kode : {{dataRujukanNoKa.rujukan.peserta.statusPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKa.rujukan.peserta.statusPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>mr</h4>
                                            <p>noMR : {{dataRujukanNoKa.rujukan.peserta.mr.noMR||"-"}}</p>
                                            <p>noTelepon : {{dataRujukanNoKa.rujukan.peserta.mr.noTelepon||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provUmum</h4>
                                            <p>kdProvider : {{dataRujukanNoKa.rujukan.peserta.provUmum.kdProvider||"-"}}</p>
                                            <p>nmProvider : {{dataRujukanNoKa.rujukan.peserta.provUmum.nmProvider||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr
                                    >  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jenisPeserta</h4>
                                            <p>Kode : {{dataRujukanNoKa.rujukan.peserta.jenisPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKa.rujukan.peserta.jenisPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>hakKelas</h4>
                                            <p>Kode : {{dataRujukanNoKa.rujukan.peserta.hakKelas.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKa.rujukan.peserta.hakKelas.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 

                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>umur</h4>
                                            <p>umurSekarang : {{dataRujukanNoKa.rujukan.peserta.umur.umurSekarang||"-"}}</p>
                                            <p>umurSaatPelayanan : {{dataRujukanNoKa.rujukan.peserta.umur.umurSaatPelayanan||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>informasi</h4>
                                            <p>dinsos : {{dataRujukanNoKa.rujukan.peserta.informasi.dinsos||"-"}}</p>
                                            <p>prolanisPRB : {{dataRujukanNoKa.rujukan.peserta.informasi.prolanisPRB||"-"}}</p> 
                                            <p>noSKTM : {{dataRujukanNoKa.rujukan.peserta.informasi.noSKTM||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>noAsuransi : {{dataRujukanNoKa.rujukan.peserta.cob.noAsuransi||"-"}}</p>
                                            <p>nmAsuransi : {{dataRujukanNoKa.rujukan.peserta.cob.nmAsuransi||"-"}}</p> 
                                            <p>tglTMT : {{dataRujukanNoKa.rujukan.peserta.cob.tglTMT||"-"}}</p> 
                                            <p>tglTAT : {{dataRujukanNoKa.rujukan.peserta.cob.tglTAT||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Rujukan Berdasarkan No Kartu, Multi Record</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-8" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpRujukanNoKaMulti" class="form-control" 
                            placeholder="Ketik No Kartu" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group mb-0">
                                <v-select placeholder="Pilih Type" v-model="jnsRujukanNoKaMulti" :options="Config.mr.typeRujukanRS" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchRujukanNoKaMulti" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetRujukanNoKaMulti">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwRujukanNoKaMulti">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody v-for="(v,k) in dataRujukanNoKaMulti.rujukan" :key="k">
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>diagnosa</h4>
                                                <p>kode : {{dataRujukanNoKaMulti.rujukan[k].diagnosa.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKaMulti.rujukan[k].diagnosa.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>keluhan</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].keluhan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>noKunjungan</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].noKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pelayanan</h4>
                                                <p>kode : {{dataRujukanNoKaMulti.rujukan[k].pelayanan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKaMulti.rujukan[k].pelayanan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukanNoKaMulti.rujukan[k].poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKaMulti.rujukan[k].poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provPerujuk</h4>
                                                <p>kode : {{dataRujukanNoKaMulti.rujukan[k].provPerujuk.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKaMulti.rujukan[k].provPerujuk.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglKunjungan</h4>
                                                <p>{{dataRujukanNoKaMulti.rujukan[k].tglKunjungan||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>poliRujukan</h4>
                                                <p>kode : {{dataRujukanNoKaMulti.rujukan[k].poliRujukan.kode||"-"}}</p>
                                                <p>nama : {{dataRujukanNoKaMulti.rujukan[k].poliRujukan.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr> 
                                    
                                    <tr>
                                        <td colspan="3">Data Pasien</td>
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Kartu</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pisa</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.pisa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>sex</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.sex||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglCetakKartu</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.tglCetakKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTAT</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.tglTAT||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTMT</h4>
                                            <p>{{dataRujukanNoKaMulti.rujukan[k].peserta.tglTMT||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>statusPeserta</h4>
                                            <p>Kode : {{dataRujukanNoKaMulti.rujukan[k].peserta.statusPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKaMulti.rujukan[k].peserta.statusPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>mr</h4>
                                            <p>noMR : {{dataRujukanNoKaMulti.rujukan[k].peserta.mr.noMR||"-"}}</p>
                                            <p>noTelepon : {{dataRujukanNoKaMulti.rujukan[k].peserta.mr.noTelepon||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provUmum</h4>
                                            <p>kdProvider : {{dataRujukanNoKaMulti.rujukan[k].peserta.provUmum.kdProvider||"-"}}</p>
                                            <p>nmProvider : {{dataRujukanNoKaMulti.rujukan[k].peserta.provUmum.nmProvider||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr
                                    >  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jenisPeserta</h4>
                                            <p>Kode : {{dataRujukanNoKaMulti.rujukan[k].peserta.jenisPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKaMulti.rujukan[k].peserta.jenisPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>hakKelas</h4>
                                            <p>Kode : {{dataRujukanNoKaMulti.rujukan[k].peserta.hakKelas.kode||"-"}}</p>
                                            <p>Keterangan : {{dataRujukanNoKaMulti.rujukan[k].peserta.hakKelas.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 

                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>umur</h4>
                                            <p>umurSekarang : {{dataRujukanNoKaMulti.rujukan[k].peserta.umur.umurSekarang||"-"}}</p>
                                            <p>umurSaatPelayanan : {{dataRujukanNoKaMulti.rujukan[k].peserta.umur.umurSaatPelayanan||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>informasi</h4>
                                            <p>dinsos : {{dataRujukanNoKaMulti.rujukan[k].peserta.informasi.dinsos||"-"}}</p>
                                            <p>prolanisPRB : {{dataRujukanNoKaMulti.rujukan[k].peserta.informasi.prolanisPRB||"-"}}</p> 
                                            <p>noSKTM : {{dataRujukanNoKaMulti.rujukan[k].peserta.informasi.noSKTM||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>noAsuransi : {{dataRujukanNoKaMulti.rujukan[k].peserta.cob.noAsuransi||"-"}}</p>
                                            <p>nmAsuransi : {{dataRujukanNoKaMulti.rujukan[k].peserta.cob.nmAsuransi||"-"}}</p> 
                                            <p>tglTMT : {{dataRujukanNoKaMulti.rujukan[k].peserta.cob.tglTMT||"-"}}</p> 
                                            <p>tglTAT : {{dataRujukanNoKaMulti.rujukan[k].peserta.cob.tglTAT||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  data() {
    return {
        inpRujukan:"",
        jnsRujukan: false,  
        shwRujukan: false,  
        dataRujukan: {},
    
        inpRujukanNoKa:"",
        jnsRujukanNoKa: false,          
        shwRujukanNoKa: false,  
        dataRujukanNoKa: {},
        
        inpRujukanNoKaMulti:"",
        jnsRujukanNoKaMulti: false,          
        shwRujukanNoKaMulti: false,  
        dataRujukanNoKaMulti: {},
    }
  },
  methods: {
    searchRujukan(){
        if(this.inpRujukan){
            this.loadingOverlay = true
            let data = {
                type : "search-rujukan",
                search : this.inpRujukan,
                jenis: this.jnsRujukan
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwRujukan = false
                    return this.$swal({
                        icon: 'error',
                        text: resp.message||'Data Rujukan Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataRujukan = resp.response
                this.shwRujukan = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Rujukan Tidak Ditemukan'
                })
            })
        }
    },
    resetRujukan(){
        this.inpRujukan = null
        this.shwRujukan = false
        this.dataRujukan = {}
    },


    searchRujukanNoKa(){
        if(this.inpRujukanNoKa){
            this.loadingOverlay = true
            let data = {
                type : "search-rujukan-noka",
                search : this.inpRujukanNoKa,
                jenis: this.jnsRujukanNoKa
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwRujukanNoKa = false
                    console.log(resp)
                    return this.$swal({
                        icon: 'error',
                        text: resp.message||'Data Rujukan Tidak Ditemukan',
                    })
                }
                this.loadingOverlay = false
                this.dataRujukanNoKa = resp.response
                this.shwRujukanNoKa = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Rujukan Tidak Ditemukan'
                })
            })
        }
    },
    resetRujukanNoKa(){
        this.inpRujukanNoKa = null
        this.shwRujukanNoKa = false
        this.dataRujukanNoKa = {}
    },

    searchRujukanNoKaMulti(){
        if(this.inpRujukanNoKaMulti){
            this.loadingOverlay = true
            let data = {
                type : "search-rujukan-noka-multi",
                search : this.inpRujukanNoKaMulti,
                jenis: this.jnsRujukanNoKaMulti
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwRujukanNoKaMulti = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Rujukan Tidak Ditemukan'
                    })
                }
                this.loadingOverlay = false
                this.dataRujukanNoKaMulti = resp.response
                this.shwRujukanNoKaMulti = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Rujukan Tidak Ditemukan'
                })
            })
        }
    },
    resetRujukanNoKaMulti(){
        this.inpRujukanNoKaMulti = null
        this.shwRujukanNoKaMulti = false
        this.dataRujukanNoKaMulti = {}
    },

  },
}
</script>